<template>
    <section class="card-icon-container">
        <h3>Encontrar el punto de venta perfecto no es suerte, son datos</h3>

        <div class="flex">
            <IconCardInfo :isFirstArticle="true" img="ai" title="IA para gestionar los puntos de venta existentes."
                text="Nuestra inteligencia artificial analiza más de 2.000 variables geolocalizadas como el número de habitantes o viviendas, el nivel de consumo o de renta, y los datos sobre competidores o movilidad." />

            <IconCardInfo img="balance" title="Simplifica un 80% la toma de tus decisiones." text="Ahorrarás en costosos estudios de mercado y prospecciones a pie de calle. Podrás convertir tu
                    expansión en un proceso eficiente y fiable, para tomar las decisiones adecuadas." />

            <IconCardInfo img="user" title="Encuentra cómo acercarte a tu cliente objetivo." text="Encontramos por ti los puntos clave para marcar la diferencia. Sabrás donde se encuentran tus
                    negocios
                    competidores y podrás dar los pasos correctos de expansión antes que ellos." />
        </div>

    </section>
</template>


<script>
import IconCardInfo from './IconCardInfo.vue'

export default {
    name: 'IconCards',
    components: {
        IconCardInfo
    }
}
</script>


<style scoped>
h3 {
    text-align: start;
    margin-bottom: 68px;
}

@media (min-width: 834px) {
    .flex {
        display: flex;
    }

    h3 {
        padding-right: 280px;
        margin-bottom: 68px;
    }

}
</style>