<template>
    <div class="footer-container">

        <img src="/Demo-logo.svg" alt="">

        <article>
            <p>Recursos útiles</p>
            <ul>
                <li><a href="#">Normativa de Alquiler</a></li>
                <li><a href="#">Ayuda y Asistencia</a></li>
                <li><a href="#">Servicios Municipales</a></li>
                <li><a href="#">Preguntas Frecuentes</a></li>
                <li><a href="#">Contacto</a></li>
            </ul>
        </article>

        <article>
            <p>Explora más</p>
            <ul>
                <li><a href="#">Eventos Locales</a></li>
                <li><a href="#">Transporte y Conectividad</a></li>
                <li><a href="#">Educación y Escuelas</a></li>
                <li><a href="#">Cultura y Patrimonio</a></li>
                <li><a href="#">Empleo y Oportunidades</a></li>
            </ul>
        </article>

        <article>
            <p>Otros Servicios</p>
            <ul>
                <li><a href="#">Emergencia y Seguridad</a></li>
                <li><a href="#">Cuidado de Salud</a></li>
                <li><a href="#">Reciclaje y Sostenibilidad</a></li>
                <li><a href="#">Parques y Espacios Verdes</a></li>
                <li><a href="#">Comercios y Compras</a></li>
            </ul>
        </article>

        <hr class="footer-hr">
        <div class="footer-bottom">

            <h5>© 2023 Pickgeo</h5>

            <div class="flex">
                <h5>Política de privacidad</h5>
                <h5>Términos de uso</h5>
            </div>

            <div class="footer-logos">
                <a href="#"><i class="fab fa-facebook-square"></i></a>
                <a href="#"><i class="fab fa-youtube"></i></a>
                <a href="#"><i class="fab fa-flickr"></i></a>
                <a href="#"><img src="../../public/x.svg" alt=""></a>
            </div>

        </div>

    </div>
</template>


<script>
export default {
    name: 'Footer'
}
</script>


<style scoped>
.footer-container {
    width: calc(100% - 80px);
    margin: 0 auto;
    text-align: start;
    margin-top: 48px;
}

img {
    display: block;
    width: 30%;
    height: auto;
    margin: auto;
    margin-bottom: 36px;
}

p {
    margin-top: 36px;
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 16px;

}

a {
    text-decoration: none;
    color: var(--base-900);
}

.footer-hr {
    border: 1px solid var(--base-900);
    margin: 48px 0 28px 0;
    width: 100%;
}

h5 {
    margin: 0 0 12px 0;
    text-align: center;
    font-weight: 400;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.flex h5 {
    margin: 0 18px;
}

.footer-logos {
    width: 40%;
    margin: 16px auto 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logos i {
    color: var(--base-900);
    font-size: 16px;
    margin: 0 6px;
}

.footer-logos img {
    display: inline;
    width: 18px;
    height: 100%;
    margin: 0 6px;
}

@media (min-width: 834px) {
    .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    article {
        width: calc(33.33% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
    }

    h5 {
        margin-left: 0;
        margin-right: 18px;
        margin-bottom: 0px;
    }

    .flex h5 {
        margin: 0 18px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-logos {
        margin: 0;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-left: auto;
    }

    img {
        width: 190px;
        margin: 0 40%;
    }

    .footer-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }
}

@media (min-width: 1280px) {
    .footer-container {
        margin-left: 68px;
        margin-right: 48px;
        max-width: calc(100% - 116px);
        width: auto;
    }

    img {
        margin: 0;
        margin-bottom: 40px;
        margin-right: 20px;
    }

    article {
        width: 23%;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 20px;
    }
}
</style>
