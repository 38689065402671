<template>
    <span>
        <div class="container chart" v-if="this.areaData.homesPeople?.length">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>

        <div class="alert-msg" v-else>
            <img src="/Exclamation Circle.svg" alt="alert">
            <span>
                <h5>¡No se pueden mostrar los datos!</h5>
                <h6>No existen datos de competidores por sector de esta zona.<strong> Por favor seleccione otra zona
                        para
                        mostrar los datos.</strong></h6>
            </span>
        </div>
    </span>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'ChartBars',
    computed: {
        ...mapState([ 'areaData' ])
    },
    data() {
        return {
            series: [ {
                data: this.areaData?.homesPeople,
                name: [ 'Hogares' ]
            } ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#c6a94b' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },

                xaxis: {
                    categories: [ '1 pers.', '2 pers.', '3 pers.', '4 pers.', '5 pers.', '>6 pers.' ],
                },
            },
        }
    },
    mounted() {
        this.series[ 0 ].data = this.areaData.homesPeople
    }
}
</script>


<style>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>