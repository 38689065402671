const formatHomesPeople = (data) => {

    const dataKeys = Object.keys(data)

    let formattedData = []

    dataKeys.forEach(elm => formattedData.push(data[elm]))

    return formattedData

}


export default formatHomesPeople