<template>
    <span>

        <p>{{ this.title }}</p>

        <div class="flex" v-if="this.data != 0">
            <h1>{{ this.data }}</h1>
            <h4>{{ this.detail }}</h4>
        </div>

        <div class="flex" v-if="this.data == 0">
            <img src="/Exclamation Circle.svg" alt="alert">
            <span>
                <h5>¡No se pueden mostrar los datos!</h5>
                <h6>No existe ninguna persona censada en la zona seleccionada. <strong> Por favor seleccione otra zona
                        para
                        mostrar los datos.</strong></h6>
            </span>
        </div>

    </span>
</template>


<script>
export default {
    name: 'DataCardInfo',
    props: {
        title: String,
        data: String,
        detail: String
    }
}
</script>


<style scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

h5 {
    font-weight: 700;
    margin: 3px 5px;
}

h6 {
    margin: 3px 5px;
}
</style>