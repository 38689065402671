<template>
    <div>
        <span>
            <img src="/Map Pin.svg" alt="pin">
            <h5>{{ this.address }}</h5>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import Button from './Button.vue'

export default {
    name: 'AddressBox',
    components: {
        Button
    },
    computed: {
        ...mapState([ 'address' ])
    },
}
</script>


<style scoped>
div {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    background-color: white;
    margin: -210px 50px 80px;
    max-width: 100%;
    position: relative;
    z-index: 1;
}

span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

img {
    width: 36px;
}

h5 {
    margin: 0;
}


@media (min-width: 834px) {
    div {
        margin: -250px 80px 100px;
        flex-direction: row;
        justify-content: space-between;

    }

}

@media (min-width: 1280px) {
    div {
        margin: -200px 150px 100px;
    }

}
</style>