<template>
    <div class="header-container">
        <img src="/Demo-logo.svg" alt="Ayuntamiento de LoremIpsum" @click="handleBackHome">

        <Button v-if="this.screenWidth > 834 && this.router.currentRoute.path != '/map'" @click="handleClick"
            buttonText="Ir a la herramienta" />

    </div>
</template>


<script>
import { mapState } from 'vuex'
import { useRouter } from 'vue-router'
import Button from './Button.vue'


export default {
    name: 'HeaderLogo',
    components: {
        Button
    },
    computed: {
        ...mapState(['screenWidth'])
    },
    data() {
        return {
            router: useRouter()
        }
    },
    methods: {
        handleClick() {
            this.$router.push({ name: 'MapPage' })
        },
        handleBackHome() {
            this.$router.push({ name: 'HomePage' })
        }
    }
}
</script>


<style scoped>
.header-container {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

img {
    width: 100px;
    height: auto;
    cursor: pointer;
}

@media (min-width: 834px) {
    .header-container {
        padding: 28px 40px;
        justify-content: space-between;
    }

    img {
        width: 152px;
        height: auto;
    }
}

@media (min-width: 1280px) {
    .header-container {
        padding: 28px 68px;
        height: 100px;
    }
}
</style>