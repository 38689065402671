<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-message-provider>

      <HeaderLogo />
      <router-view />
      <Footer />

    </n-message-provider>
  </n-config-provider>
</template>


<script>
import { mapActions } from 'vuex'
import { useRouter } from 'vue-router'
import { NConfigProvider, NMessageProvider } from 'naive-ui'
import HeaderLogo from './components/HeaderLogo.vue'
import Footer from './components/Footer.vue'


export default {
  name: "App",
  components: {
    NConfigProvider,
    NMessageProvider,
    HeaderLogo,
    Footer
  },

  data() {
    return {
      router: useRouter(),
      themeOverrides: {
        common: {
          primaryColor: '#c6a94b',
          errorColor: '#d00f08',
          successColor: '#049625',
        },
        Select: {
          peers: {
            InternalSelection: {
              textColor: 'black',
              textAlign: 'start',
              border: '1px solid #000',
              borderRadius: '0px',
              borderActive: '1px solid #c6a94b',
              borderFocus: '1px solid #c6a94b',
              borderHover: 'none',
              boxShadowActive: 'none',
              boxShadowFocus: 'none',
              arrowColor: 'black'
            },
            InternalSelectMenu: {
              optionCheckColor: '#c6a94b',
              optionTextColorActive: '#c6a94b',
              optionTextColorPressed: '#c6a94b',
            }
          }
        },
        Input: {
          textColor: 'black',
          textAlign: 'start',
          border: '1px solid #000',
          borderRadius: '0px',
          borderActive: '1px solid #c6a94b',
          borderFocus: '1px solid #c6a94b',
          borderHover: 'none',
          boxShadowActive: 'none',
          boxShadowFocus: 'none',
          textColorDisabled: '#6f6f6f'
        }
      }
    }
  },

  methods: {
    ...mapActions([ "setScreenWidth" ]),

    handleResize() {
      this.setScreenWidth(window.innerWidth)
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize)
  },

  beforeUnmount() {
    window.addEventListener("resize", this.handleResize)
  }
}
</script>


<style>
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;

}

:root {
  --base-50: #ffff;
  --base-100: #f6f6f6;
  --base-200: #f2f2f2;
  --base-300: #dedede;
  --base-400: #c8c8c8;
  --base-600: #6f6f6f;
  --base-700: #5d5d5d;
  --base-800: #424242;
  --base-900: #212121;
  --primary: #c6a94b;
  --error: #d00f08;
  --success: #049625;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;

}

::-webkit-scrollbar-track {
  display: none;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 10px;
  scrollbar-color: #6f6f6f;
}

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 42px;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h6 {
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.n-button .n-button__content {
  padding: 12px 24px;
}

.form-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.25);
  z-index: 2;
}


@media (min-width: 1280px) {
  #app {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

  }

  h1 {
    font-size: 48px;
    line-height: 60px;
  }

  h2 {
    font-size: 42px;
    line-height: 46px;
  }

  h3 {
    font-size: 34px;
    line-height: 42px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
  }
}
</style>
